<template>
  <v-app>
    <v-container fluid class="py-0 fill-height">
      <v-row class="fill-height" align="center">
        <v-col cols="12" sm="6" style="background-color: white">
          <v-row align="center" class="py-20">
            <v-card flat class="mx-auto">
              <v-img
                src="/cti_mo_logo.png"
                class="cti-mo-icon mx-auto"
                max-width="20%"
              />
              <v-card-title class="justify-center font-weight-bold">
                石岡市乗合い<br />タウンメイト
              </v-card-title>
            </v-card>
          </v-row>
          <v-row align="center" class="py-20 mt-5 mb-5">
            <v-card
              flat
              class="mx-auto"
              outlined
              style="border-color: red; border-size: 4px;"
            >
              <!--
              <v-card-text class="justify-left font-weight-bold" color="black">
                石岡市乗合いタウンメイト実証実験（12月9日~13日）は、<br />終了しました。
                ご協力ありがとうございました
              </v-card-text>
              -->
            </v-card>
          </v-row>
          <v-row align="center" class="fill-height">
            <nuxt />
          </v-row>
        </v-col>
        <v-col cols="12" sm="6" class="fill-height map-container">
          <GmapMap
            :options="{
              zoomControl: true,
              mapTypeControl: false,
              scaleControl: false,
              streetViewControl: false,
              rotateControl: false,
              fullscreenControl: true,
              disableDefaultUi: true
            }"
            :center="{ lat: 36.1921811, lng: 140.2717822 }"
            :zoom="15"
            style="width: 100%;height: 100%"
          >
          </GmapMap>
        </v-col>
      </v-row>
    </v-container>
  </v-app>
</template>

<script>
import { createComponent } from '@vue/composition-api'
export default createComponent({
  setup() {}
})
</script>

<style>
.cti-mo-green {
  background-color: #00ac7d;
}
.cti-mo-icon {
  filter: drop-shadow(10px 10px 10px rgba(0, 0, 0, 0.2));
}
</style>
