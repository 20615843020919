import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/auth'

export const firebaseApp = firebase.initializeApp({
  apiKey: 'AIzaSyA2hEHq2G32SI80dvv-s_Akg_eQhwy2R2Q',
  authDomain: 'cti-ondemand-255210.firebaseapp.com',
  databaseURL: 'https://cti-ondemand-255210.firebaseio.com',
  projectId: 'cti-ondemand-255210',
  storageBucket: 'cti-ondemand-255210.appspot.com',
  messagingSenderId: '271897390108',
  appId: '1:271897390108:web:26828b86dfb0cec11a83d9'
})
export const firestore = firebaseApp.firestore()

const { Timestamp } = firebase.firestore
export { Timestamp }
